<!-- 超V优惠码 -->
<template>
  <div class="container">
    <canvas id="myCanvas" ref="myCanvas"
      :style="`position:fixed;top:${height}px;left:${width}px;width:${width}px;height:${height}px`" />
    <van-tabs v-model="active" swipeable background="#F8F8F8" title-active-color="#FFB5BB">
      <van-tab title="优惠码">
        <div class="flow-hint">
          <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220606134244360.png" />
        </div>
        <div class="coupon-cont">
          <div class="code-box" v-if="cardId > 0 && couponCode">
            <span>优惠码：</span><span>{{ couponCode }}</span>
            <span class="copy-btn" @click.stop v-clipboard:copy="couponCode" v-clipboard:success="copy">复制</span>
            <span class="copy-btn" @click="handleShare">分享</span>
          </div>
          <div class="code-box" v-else>
            <span>优惠码：</span>
            <span class="copy-btn alright" @click="handleShare">点击生成优惠码</span>
          </div>
          <div class="prompt"><span class="label">点击复制：</span>
            <div class="cont">系统将自动粘贴，您可将优惠码发送给超V噢！</div>
          </div>
          <div class="prompt"><span class="label">点击分享：</span>
            <div class="cont">生成优惠码分享卡，长按保存至相册后可微信发给Ta噢！</div>
          </div>
        </div>
      </van-tab>
      <van-tab title="超V采购商品记录">
        <div class="prompt-cont">
          <p>已采购：超V已定制美妆/大健康商品</p>
          <p>未采购：超V已输入优惠码，但未采购订货</p>
        </div>
        <van-list v-model="loading" :finished="finished" finished-text="" @load="getDatalist">
          <div class="no-data" v-if="finished && datalist.length < 1">
            <div class="bg-circle">
              <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20190109105729702.jpg">
            </div>
            <p>暂无数据</p>
          </div>
          <div class="cus-cell" :class="{ 'unbuy': !item.isBuy }" v-for="(item, index) in datalist" :key="index">
            <div class="user-info">
              <span>姓名：{{ item.name }}</span>
              <span>电话：{{ item.phone }}</span>
              <a :href="'tel:' + item.phone" class="btn">
                <van-icon name="phone" size="12" />
              </a>
              <div class="btn" v-clipboard:copy="item.phone" v-clipboard:success="copy">复制</div>
            </div>
            <div class="time">
              <span>{{ item.dateTimes }}</span>
              <span class="status">{{ item.isBuy ? '已' : '未' }}定制{{ item.brandId == 4 ? '大健康' : '美妆' }}</span>
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
    <van-popup v-model="showPosterLayer">
      <div class="poster-cont">
        <img :src="posterTemp" />
        <div class="download" v-if="couponCode">
          <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220606151744169.png" />
          长按图片识别保存
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'SuperVDiscountCode',
  data() {
    return {
      active: 0,
      couponCode: '',
      datalist: [],
      pg: 0,
      ps: 10,
      loading: false,
      finished: false,
      posterTemp: '',
      showPosterLayer: false,
      qrCode: '',
      width: 750,
      height: 1007,
      cardId: 0, //店主的店铺id
      userId: 0,//店主的公众号userid
      brandId: 3,//店主的品牌id
    }
  },
  created() {
    this.getCouponCode();
  },
  methods: {
    getCouponCode() {
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true });
      this.get("/OrderGoods/ExperienceOrder/GetExperienceCode", {}, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          let { experienceCode, brandId, userId, cardId } = res.response;
          this.couponCode = experienceCode;
          this.brandId = brandId;
          this.userId = userId;
          this.cardId = cardId;
        }
      })
    },
    getDatalist() {
      this.pg++;
      this.get("/OrderGoods/ExperienceOrder/GetAgenciesExperienceList", {
        pageIndex: this.pg,
        pageSize: this.ps
      }, 2).then(res => {
        this.loading = false;
        if (res.code === 1) {
          let list = res.response || [];
          this.datalist = this.datalist.concat(list);
          if (list.length < this.ps) {
            this.finished = true;
          }
        } else {
          this.finished = true;
        }
      })
    },
    copy() {
      this.$toast('复制成功')
    },
    handleShare() {
      if (this.posterTemp) return this.showPosterLayer = true;
      this.$toast.loading({ message: '海报生成中...', duration: 0, overlay: true });
      if (this.cardId <= 0 || !this.couponCode) {
        this.width = 726;
        this.height = 1210;
        this.generateQrCode2();
        this.downloadMaterial("http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220610130433639.png");
      } else {
        this.width = 750;
        this.height = 1007;
        this.generateQrCode();
        this.downloadMaterial("http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220606163248823.png");
      }

    },
    //下载素材
    downloadMaterial(backgroundImage) {
      let img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      this.getImageBlob(backgroundImage, (res) => {
        img.src = res;
        this.beforeDraw(img);
      });
    },
    getImageBlob(url, cb) {
      let xhr = new XMLHttpRequest();
      xhr.open("get", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (this.status === 200) {
          if (cb) cb(URL.createObjectURL(this.response));
        }
      };
      xhr.send();
    },
    generateQrCode: function () {
      this.get("/Base/Tools/AddLinkUrl", {
        v: 1,
        linkUrl: `a=${this.cardId}&c=${this.userId}&d=22&h=${this.couponCode}&i=${this.brandId}`
      }, 2).then(({ code, response }) => {
        if (code === 1 && response) {
          this.post("/Base/Tools/GetMiNiQrCode?", {
            companyId: 8001,
            scene: "@=" + response.linkUrlKey,
            page: "packageD/pages/share/index",
          }).then((json) => {
            if (json.code === 1) {
              let img = new Image();
              img.setAttribute("crossOrigin", "anonymous");
              this.getImageBlob(json.response, (res) => {
                img.src = res;
                this.qrCode = img;
              });
            } else {
              this.$toast("二维码生成失败");
            }
          });
        } else this.$toast("二维码生成失败")
      }).catch(() => {
        this.$toast("二维码生成失败")
      })

    },
    generateQrCode2: function () {
      let img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      this.getImageBlob('http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220610130242466.jpg', (res) => {
        img.src = res;
        this.qrCode = img;
      });
    },
    beforeDraw(tempBgImage) {
      if (!this.qrCode) {
        return setTimeout(() => {
          this.beforeDraw(tempBgImage)
        }, 200);
      }
      setTimeout(() => {
        this.draw(tempBgImage);
      }, 100)
    },
    draw(tempBgImage) {
      let canvas = this.$refs.myCanvas;
      canvas.width = this.width;
      canvas.height = this.height;
      let ctx = canvas.getContext("2d");
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, this.width, this.height);
      ctx.drawImage(tempBgImage, 0, 0, this.width, this.height); //背景图片
      ctx.fillStyle = "RGBA(0, 0, 0, 0.1)";
      if (this.cardId > 0 && this.couponCode) {
        ctx.fillRect(253, 368, 244, 244);
        ctx.drawImage(this.qrCode, 255, 370, 240, 240);
        ctx.font = "40px HelveticaNeue";
        ctx.fillStyle = "#333";
        ctx.textAlign = "center";
        ctx.fillText(this.couponCode, 375, 190); // 1158
      } else {
        ctx.fillRect(212, 228, 304, 304);
        ctx.drawImage(this.qrCode, 213, 230, 300, 300);
      }
      setTimeout(() => {
        this.posterTemp = canvas.toDataURL("image/png");
        this.$toast.clear();
        this.showPosterLayer = true;
      }, 100)
    },
  }
}
</script>
<style lang='scss' scoped>
.container {
  height: 100%;
  background-color: #F8F8F8;

  ::v-deep .van-popup {
    border-radius: 12px;
  }

  ::v-deep .van-tabs {
    .van-tabs__wrap {
      .van-tabs__nav {
        .van-tab {
          &.van-tab--active {
            font-weight: bold;
          }
        }
      }

      .van-tabs__line {
        background-color: #FFB5BB;
      }
    }

    .van-tabs__content {
      padding: 11px 15px;
    }
  }

  .flow-hint {
    margin-top: 10%;

    img {
      width: 100%;
    }
  }

  .coupon-cont {
    padding: 23px 15px;
    margin-top: 10px;
    border-radius: 6px;
    background-color: #FFFFFF;

    .code-box {
      color: #fff;
      font-size: 14px;
      overflow: hidden;
      padding: 20px 25px;
      border-radius: 4px;
      margin-bottom: 10px;
      background-color: #FFB5BB;

      .copy-btn {
        color: #FFB5BB;
        padding: 5px 10px;
        border-radius: 13px;
        margin-left: 10px;
        background-color: #fff;
      }

      .alright {
        float: right;
      }
    }

    .prompt {
      color: #666666;
      padding-top: 5px;
      display: flex;

      .label {
        flex-shrink: 0;
      }

      .cont {
        flex: 1;
      }
    }
  }

  .prompt-cont {
    color: #FF98A0;
    margin: 0 -15px;
    font-size: 10px;
    padding: 11px 15px;
    background-color: #FFF5F6;

    p {
      padding-bottom: 5px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .van-list {
    position: fixed;
    top: 130px;
    left: 15px;
    right: 15px;
    bottom: 0;
    overflow-y: auto;
    background-color: #FFFFFF;
    border-radius: 6px;

    .cus-cell {
      color: #999;
      padding: 11px 15px;
      position: relative;

      .user-info {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: 8px;

        span {
          padding-right: 10px;
        }

        .btn {
          font-size: 10px;
          padding: 3px 10px;
          color: #fff;
          margin-right: 10px;
          border-radius: 13px;
          background-color: #FF98A0;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .time {
        font-size: 10px;

        .status {
          padding-left: 10px;
          font-weight: bold;
        }
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: #F1F1F1;
      }

      &.unbuy {
        .user-info {
          color: #333;
        }

        .time {
          .status {
            color: #FF98A0;
          }
        }
      }
    }
  }

  .no-data {
    text-align: center;

    .bg-circle {

      img {
        width: 260px;
      }
    }

    p {
      font-size: 14px;
      color: #999;
      padding-top: 10px;
    }
  }

  .poster-cont {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #D8CAFF;

    img {
      width: 280px;

    }

    .download {
      font-size: 14px;
      padding: 5px 0 14px;
      display: flex;
      align-content: center;

      img {
        width: 20px;
        margin-right: 10px;
      }
    }
  }
}
</style>